import { axiosConfig } from '../api/axios-config.js'

// 裝置
export const fora = {
  // 取得清單
  get: (id, token) => axiosConfig.get(`/api/fora/${id || ''}`, token),

  // 創建
  create: (data, token) => axiosConfig.post('/api/fora/device', data, token),

  // 更新
  update: (id, data, token) => axiosConfig.patch(`/api/fora/${id}`, data, token),

  // 刪除
  delete: (id, token) => axiosConfig.delete(`/api/fora/${id}`, token)
}
